.MainContainer {
  background-color: black;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.loader {
  color: white;
  text-align: center;
  margin-top: 120px;
  height: 429px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Spinner {
  border: 10px solid #ffffff;
  border-top: 10px solid #000000;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  width: 200px;
  height: 200px;
}

/* section One */
.SectionOne {
  background-image: url("./assets/SectionOne.png");
  background-blend-mode: multiply;
  background-color: #121212;
  position: relative;
}
.incrimentButton {
  user-select: none;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 7px;
  color: #2d2d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
  z-index: 2;
}
.mintNumber {
  width: 50px;
  text-align: center;
  color: white;
  font-style: italic;
  font-weight: bold;
}
.mintNow {
  margin: 20px auto 10px auto;
  background-color: #fdc697;
  color: #2d2d2d;
  font-weight: bold;
  display: flex;
  height: 50px;
  width: 200px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  border: 3px solid #000000d9;
}
.Circle {
  font-size: 20px;
  font-weight: 100;
  padding: 0px 20px;
}
.CursorPointer {
  cursor: pointer;
}
.NavBarMargins {
  padding-bottom: 60px;
}
.ShopButton {
  transition: 0.2s ease;
}
.ShopButton:hover {
  transform: scale(1.05);
  transition: 0.2s ease;
}
.NavIconPadding {
  padding: 0px 5px;
  transition: 0.2s ease;
}
.NavIconPadding:hover {
  transform: scale(1.1);
  transition: 0.2s ease;
}
.LBHImage {
  margin: 0 21px;
}
.LBH2 {
  position: relative;
  top: 75px;
}
.LBH3 {
  position: relative;
  top: 125px;
}
.T1 {
  position: absolute;
  padding-left: 125px;
  padding-bottom: 80px;
  padding-right: 20px;
}
.T2 {
  position: absolute;
  padding-left: 383px;
  padding-top: 10px;
}
.MintCount {
  text-align: center;
  color: #ffffff;
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  line-height: 120px;
  margin-top: 120px;
}
.BoxHeadMinted {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.035em;
  color: #7c7c7c;
  text-align: center;
  margin-bottom: 26px;
}
.MintContainer {
  min-height: 153px;
  margin-bottom: 100px;
}
.ConnectWallet {
  max-width: 402px;
  height: 93px;
  background: rgba(255, 255, 255, 0.2);
  border: 5px dashed #70f8ba;
  box-sizing: border-box;
  border-radius: 32px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  /* font-size: 48px; */
  line-height: 61px;
  color: #ffffff;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease;
}
.ConnectWallet:hover {
  background-color: black;
  border-color: white;
  transform: scale(1.02);
  transition: 0.2s ease;
}
.TiltedSlider {
  transform: rotate(-6deg);
}

/* Section Two */
.CheckOutTrailer {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 16px;
}
.SalePaused {
  text-align: center;
  color: #f44336;
  font-size: 30px;
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
}
.Video {
  width: 50vw;
  /* max-width: 500px; */
  height: 50vw;
  /* max-height: 500px; */
  border-radius: 32px;
  background-color: #202020;
  margin: auto;
  margin-bottom: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.VideoStyle {
  height: 50vw !important;
  width: 50vw !important;
  border-radius: 20px;
}

/* Section Three */
.SectionThree {
  background-image: url("./assets/Section3Bg.png");
  position: relative;
}
.GifStyle {
  border: 8px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: black;
}
.TextContainer {
  padding: 80px;
  height: fit-content;
}
.HeadingText {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
}
.DescriptionText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}
.PriceGrid {
  height: auto;
  /* max-width: 972px; */
  /* max-width: 972px; */
  border: 1px dashed #7c7c7c;
  border-radius: 16px;
  margin: auto;
  /* /* display: flex; */
  align-items: center;
  justify-content: center;
  justify-content: space-around;
}
/* .GridSection {
  width: 320px;
} */
.GridHeading {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 12px;
  text-align: center;
}
.GridText {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  text-align: center;
}

/* Section Four */
.SectionFour {
  background-color: black;
  position: relative;
  margin-bottom: 100px;
}
.TieringSystem {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  text-align: center;
  margin-top: 139px;
  margin-bottom: 47px;
}
.TierContainer {
  text-align: center;
  color: white;
  margin: 0 6px;
}
.TierText {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  color: #707070;
  margin-top: 16px;
  margin-bottom: 43px;
}
.TierStr {
  color: white;
}

/*Section Five*/
.CategoryBox {
  margin: 0 6px;
  margin-top: 43px;
  margin-bottom: 22px;
  width: 173px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: #707070;
}
.RoadMapHeading {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 35px;
  color: #ffffff;
}
.RoadMapIcon {
  margin: auto;
}
.RoadMapContainer {
  margin-top: 55px;
}
.RoadMapSnake {
  padding: 0 30px;
  margin-top: 10px;
}
.StepBox {
  /* min-width: 438px; */
  max-width: 348px;
  margin-bottom: 70px;
  width: 100%;
}
.Steps {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
  margin-left: 9px;
}
.StepType {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px dashed;
}
.StepText {
  margin-top: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
}

/*Section Six*/
.MeetTheTeam {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  margin-bottom: 8px;
  text-align: center;
}
.OurTeamText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  margin-bottom: 8px;
  text-align: center;
}
.LearnAboutTeam {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border-bottom: 1px dashed #70f8ba;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 28px;
}
.Name {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.Profession {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6);
}
.TeamSection {
  margin: 0 6px 36px;
}
.SocialIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
}

/*Section Seven*/
.SectionSeven {
  background-image: url("./assets/SectionSevenBg.png");
  position: relative;
  background-size: cover;
}
.FAQHeading {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
  color: #121212;
  text-align: center;
  padding-top: 83px;
  padding-bottom: 36px;
}
.AccordianStyle {
  max-width: 609px;
  margin: auto;
  padding-bottom: 85px;
}
.AccordianHeaderStyle {
  outline: none !important;
  box-shadow: none !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;
  color: black !important;
}
.AccordianBodyStyle {
  background: rgba(18, 18, 18, 0.8) !important;
  border-radius: 0 0 8px 8px !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #ffffff !important;
}

/*Section Eight*/
.SectionEight {
  background-color: black;
}
.LeftTextContainer {
  width: 280px;
  margin-right: 60px;
}
.HittingHardQuestions {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.HittingHardDescription {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  margin-top: 33px;
}
.AccordianHeaderStyle2 {
  outline: none !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: left !important;
  color: #ffffff !important;
}
.AccordianBodyStyle2 {
  background: black !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 32px !important;
  color: #ffffff !important;
}
.FooterLeftText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.LastFooterText {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  color: #707070;
  text-align: center;
  margin-bottom: 26px;
}

/* Utility Classes */
.PB-66 {
  padding-bottom: 66px;
}
.MB-43 {
  margin-bottom: 43px;
}
.H-44 {
  height: 44px;
}
.MT-85 {
  margin-top: 85px;
}
.MB-8 {
  margin-bottom: 8px !important;
}
.MB-42 {
  margin-bottom: 42px;
}
.MT--10 {
  margin-top: -10px;
}
.MT-16 {
  margin-top: 16px;
}
.MT-60 {
  margin-top: 60px;
}
.PB-30 {
  padding-bottom: 30px;
}
.W-350 {
  min-width: 350px;
}
.pt-16 {
  padding-top: 16px;
}
.FlexStart {
  justify-content: flex-start !important;
}
.FlexEnd {
  justify-content: flex-end !important;
}

.FlexDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoopingMarquee {
  position: relative;
  width: 100vw;
  font-family: Space Grotesk;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed #ffffff;
  border-bottom: 1px dashed #ffffff;
  max-width: 120%;
  height: 63px;
  overflow-x: hidden;
  color: white;
  line-height: 31px;
  letter-spacing: 5px;
  font-weight: 700;
  font-size: 24px;
  background-color: black;
  z-index: 10;
}
.Track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 30s linear infinite;
}
@keyframes marquee {
  from {
    transform: translateX(-35%);
  }
  to {
    transform: translateX(0);
  }
}

/*accordian styles*/

.Tab {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgb(251 230 222) 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  backdrop-filter: blur(42px) !important;
  border-radius: 8px !important;
}

.Tab2 {
  background-color: #292929 !important;
  border-radius: 8px !important;
}

/*Modal Styling*/
.ModalHeaderStyling {
  background-color: #262626;
  border-bottom: 1px solid black !important;
}
.ModalBodyStyling {
  background-color: #262626;
  padding: 35px !important;
}
.ModelHeading {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
}
.ModalDesc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 12px;
  margin-bottom: 55px;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  margin: 0 2px;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  color: white;
  background-color: #3e3d3d;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: #dee2e6 #dee2e6 #fff !important;
  background-color: #70f8ba !important;
  border: none !important;
  color: black !important;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #70f8ba !important;
}
.nav-tabs {
  border: none !important;
}
.TabContent {
  width: 60%;
  padding: 0 20px;
}
.TabContentHeader {
  font-family: Kavoon;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-bottom: 4px;
}
.TabContentDesc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  color: #707070;
  margin-bottom: 23px;
}
.TabContentDetails {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}
.TabImage {
  width: 40%;
}
