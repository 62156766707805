.MainContainer {
  background-color: black;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.loader {
  color: white;
  text-align: center;
  margin-top: 120px;
  height: 429px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Spinner {
  border: 10px solid #ffffff;
  border-top: 10px solid #000000;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  width: 200px;
  height: 200px;
}

/* section One */
.Section-one {
  background-blend-mode: multiply;
  background-color: #121212;
  position: relative;
}
.main-heading {
  font-family: Kavoon;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
}

.container-margin {
  width: 1060px !important;
  margin: auto !important;
}
.last-updated {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  color: #ffffff;
}
.intro-heading {
  margin-top: 50px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.intro-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.general-heading {
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.Accordian-Style {
  width: 1060px;
  padding-bottom: 85px;
}
.Accordian-Header-Style {
  outline: none !important;
  box-shadow: none !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;
  color: black !important;
}
.Accordian-Body-Style {
  background: rgba(18, 18, 18, 0.8) !important;
  border-radius: 0 0 8px 8px !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #ffffff !important;
}
